

















































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { FundDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "FundDonationOrder",
  components: {
    PagedTableView,
    AbSelect,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundDonationOrder extends Vue {
  @Ref() readonly pagedTableView!: any;
  tableItems: FundDto[] = [];

  queryForm: any = {
    fundName: "",
    fundProjectName: "",
  };

  // 获取表数据
  fetchData(params: any) {
    return api.fundDonationOrder.getAll(params);
  }

  // 查看详情
  jumpDetail(row: FundDto) {
    this.$router.push({
      name: "fundProjectDetail",
      params: { id: row.id!.toString() },
    });
  }
}
